// extracted by mini-css-extract-plugin
export var webAppDevBenefits = "p_bJ";
export var webAppDevBusinesses = "p_bH";
export var webAppDevCaseSection = "p_bQ";
export var webAppDevClientQuotes = "p_bT";
export var webAppDevCostSection = "p_bM";
export var webAppDevExperienceSection = "p_bP";
export var webAppDevExpertiseSection = "p_bS";
export var webAppDevPracticesSection = "p_bN";
export var webAppDevProcessSection = "p_bK";
export var webAppDevProjLogoSection = "p_bR";
export var webAppDevServiceIndustries = "p_bG";
export var webAppDevServiceSection = "p_bF";
export var webAppDevTechStackSection = "p_bL";